define('ppchrome/services/cloud-eftpos', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Service.extend({
    appAuth: _ember['default'].inject.service(),
    localStore: _ember['default'].inject.service(),
    store: _ember['default'].inject.service(),
    settings: _ember['default'].inject.service(),

    sdk: null,
    connected: false,
    ready: false,
    paired: false,
    pinpadName: '',

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      var comms = DummyComms;
      if (this.get('settings.is_chrome_app')) {
        comms = ChromeSerialComms;
      }
      /* Toggle / at start to enable/disable
      if (chrome && chrome.sockets) {
        comms = ChromeSocketComms;
      }
      //*/
      this.get('localStore').getValue('settings', function (settings) {
        // Note - have to get settings out here since we can't rely in get('settings')
        // being populated yet
        // Use Whistle if enabled, or if Windows and not a chrome app
        if (settings && settings.use_whistle_comms === true || !_this.get('settings.is_chrome_app') && _this.get('settings.is_windows')) {
          comms = WhistleComms;
          WhistleComms.allow_auto_update = true;
        }
        _this.set('sdk', new CloudEftposSDK(_this.get('settings.prod_mode'), function () {
          /* on load */
          var sdk = _this.get('sdk');
          //temp
          window.sdk = sdk;
          // Register for status changes
          sdk.registerForStatusChanges(function (status) {
            // Update any local states
            _this.set('connected', status.connected);
            _this.set('ready', status.ready);
            _this.set('paired', status.paired);
            _this.set('pinpadName', status.pinpadName ? status.pinpadName : '');
          });
          // Transaction recovery
          var localStore = _this.get('localStore');
          localStore.getValue('inProgress', function (inProgress) {
            if (!inProgress || !inProgress.id) {
              return;
            }
            _this._recover_transaction(sdk, localStore, inProgress);
          });
        }, comms)); // SerialComms));
      });
    },

    _recover_transaction: function _recover_transaction(sdk, localStore, inProgress) {
      var _this2 = this;

      this.get('store').findRecord('transaction', inProgress.id).then(function (record) {
        if (!record) {
          sdk.alert("Pocket Pay has recovered from a crash, but is unable to recover a lost transaction.");
          return;
        }
        sdk.alert("Pocket Pay has recovered from a crash. Click OK to recover the last transaction.", "Transaction Recovery", null, function () {
          sdk.recoverTransaction(inProgress.posReference, inProgress.type, null, true, function (results, error) {
            _this2.store_result(record, results, error);
            // Clear in progress
            localStore.storeValue('inProgress', false);
            if (error) {
              sdk.alert(error.message, null, "Ok");
              return;
            }
            var title = results.approved ? "Approved" : "Declined";
            var data = {
              receipt: results.receipt,
              title: title
            };
            if (results.receipt) {
              (function () {
                var receipt = sdk._displayTemplate('receipt', data, true);
                $(".ce-close", receipt).click(function () {
                  sdk._removeTemplate(receipt);
                });
              })();
            } else {
              sdk.alert(results.responseText + ' (' + results.responseCode + ')', title);
            }
          });
        });
      })['catch'](function () {
        sdk.alert("Pocket Pay has recovered from a crash, but is unable to recover a lost transaction.");
      });
    },

    create_record: function create_record(request, products) {
      var data = {
        type: request.transactionType === TransactionRequest.PURCHASE ? 'purchase' : 'refund',
        complete: false,
        amount: request.amount,
        cashoutAmount: request.cashOut,
        posReference: request.posReference,
        notes: request.posNotes
      };
      var record = this.get('store').createRecord('transaction', data);
      if (products) {
        record.set('products', products);
        // Make sure data propagates (bug in Ember?)
        record.notifyPropertyChange('items');
      }
      return record;
    },

    store_result: function store_result(record, results, error) {
      if (!results && error) {
        record.set('message', error.message + ' (' + error.code + ')');
        record.set('complete', true);
        record.save();
        return;
      }
      record.set('datetime', results.dateTime);
      record.set('approved', results.approved);
      record.set('receipt', results.receipt);
      record.set('signatureReceipt', results.signatureReceipt);
      record.set('responseText', results.responseText);
      record.set('responseCode', results.responseCode);
      record.set('amount', results.amount);
      record.set('cashoutAmount', results.cashOut);
      record.set('tipAmount', results.tipAmount);
      record.set('surcharge', results.surcharge);
      record.set('account', results.account);
      record.set('caid', results.CAID);
      record.set('catid', results.CATID);
      record.set('authID', results.authID);
      record.set('stan', results.STAN);
      record.set('settlementDate', results.settlementDate);
      record.set('truncatedPAN', results.truncatedPAN);
      record.set('cardName', results.cardName);
      record.set('businessName', results.businessName);
      record.set('businessABN', results.businessABN);
      record.set('guid', results.guid);
      record.set('receiptURL', results.receiptURL);
      record.set('complete', true);
      record.save();
    }
  });
});