define('ppchrome/controllers/settings', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    appAuth: _ember['default'].inject.service(),
    cloudEftpos: _ember['default'].inject.service(),
    settings: _ember['default'].inject.service(),
    printer: _ember['default'].inject.service(),
    updater: _ember['default'].inject.service(), // ONLY FOR TESTING

    init: function init() {
      this._super.apply(this, arguments);
      // Populate product_buttons array
      this.update_products();
    },

    products_max_id: _ember['default'].computed('product_buttons', function () {
      return this.get('product_buttons').length - 1;
    }),

    has_products: _ember['default'].computed('product_buttons', function () {
      return this.get('product_buttons').length > 0;
    }),

    use_whistle_comms_toggle: _ember['default'].computed('settings.use_whistle_comms', {
      get: function get() /*key*/{
        return this.get('settings.use_whistle_comms');
      },
      set: function set(key, value) {
        var _this = this;

        this.set('settings.use_whistle_comms', value);
        this.get('cloudEftpos.sdk').prompt(null, "Pocket Pay needs to restart to enable or disable Whistle.<br /><br />Changing comms mode will unpair your PINpad.", false, ["Cancel", "Restart"], function (key) {
          if (key === "Restart") {
            _this.get('cloudEftpos.sdk').unpairDevice();
            chrome.runtime.reload();
          } else {
            _this.set('settings.use_whistle_comms', !value);
          }
        });
        return value;
      }
    }),

    actions: {
      reset_login: function reset_login() {
        this.get('appAuth').setAuthed(false);
        this.transitionToRoute('setup');
      },
      test_print: function test_print() {
        this.get('printer').print('\n------------------------\n  This is a test print\n\n\nYour printer is working\n       correctly\n\n========================');
      },
      check_update: function check_update() {
        var sdk = this.get('cloudEftpos.sdk');
        var loading = sdk.loading("Please wait...", "Checking for Updates");
        chrome.runtime.requestUpdateCheck(function (status) {
          loading.close();
          if (status === "update_available") {
            sdk.alert("An update is available and will be ready shortly. You will be notified once it has been downloaded.");
          } else if (status === "no_update") {
            sdk.alert("You're running the latest version!");
          } else if (status === "throttled") {
            sdk.alert("Unable to check for latest version. Please try again later.");
          }
        });
      },
      fake_update: function fake_update() {
        this.set('updater.updateAvailable', '1.2.3');
      },
      save_product_buttons: function save_product_buttons() {
        this.update_settings_products(this.get('product_buttons'));
      },
      add_product: function add_product() {
        var products = this.get('product_buttons');
        products.pushObject({
          id: products.length,
          name: '',
          price: '0'
        });
        this.update_settings_products(products);
        this.update_products();
      },
      delete_product: function delete_product(index) {
        var products = this.get('product_buttons');
        products.splice(index, 1);
        this.update_settings_products(products);
        this.update_products();
      },
      move_product_up: function move_product_up(index) {
        var new_id = index - 1;
        if (new_id < 0) {
          return;
        }
        this.swap_products(index, new_id);
      },
      move_product_down: function move_product_down(index) {
        var new_id = index + 1;
        if (new_id >= this.get('product_buttons').length) {
          return;
        }
        this.swap_products(index, new_id);
      }
    },

    update_products: function update_products() {
      var id = 0;
      var buttons = this.get('settings.product_buttons').map(function (product) {
        return {
          id: id++,
          name: product.name,
          price: parseFloat(product.price).toFixed(2)
        };
      });
      this.set('product_buttons', buttons);
    },
    swap_products: function swap_products(id1, id2) {
      var products = this.get('product_buttons');
      var tmp_button = products[id1];
      products[id1] = products[id2];
      products[id2] = tmp_button;
      _ember['default'].set(products[id1], 'id', id1);
      _ember['default'].set(products[id2], 'id', id2);
      this.set('product_buttons', products);
      this.notifyPropertyChange('product_buttons');
      this.update_settings_products(products);
    },
    update_settings_products: function update_settings_products(new_products) {
      var _this2 = this;

      var products = new_products.map(function (product) {
        var price = parseFloat(product.price);
        if (price < 0) {
          price = 0;
          _ember['default'].set(_this2.get('product_buttons')[product.id], 'price', price);
        }
        var priceCheck = Math.round(price * 100) / 100;
        if (priceCheck !== price) {
          price = priceCheck;
          _ember['default'].set(_this2.get('product_buttons')[product.id], 'price', price.toFixed(2));
        }
        return {
          name: product.name,
          price: price
        };
      });
      this.set('settings.product_buttons', products);
    }
  });
});