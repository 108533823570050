define('ppchrome/models/transaction', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
  exports['default'] = _emberData['default'].Model.extend({
    datetime: _emberData['default'].attr('date', { defaultValue: function defaultValue() {
        return new Date();
      } }),
    type: _emberData['default'].attr('string', { defaultValue: 'purchase' }), // purchase or refund
    complete: _emberData['default'].attr('boolean', { defaultValue: false }),

    approved: _emberData['default'].attr('boolean'),
    receipt: _emberData['default'].attr('string'),
    signatureReceipt: _emberData['default'].attr('string'),
    responseCode: _emberData['default'].attr('string'),
    responseText: _emberData['default'].attr('string'),
    message: _emberData['default'].attr('string'),

    amount: _emberData['default'].attr('number', { defaultValue: 0 }),
    cashoutAmount: _emberData['default'].attr('number', { defaultValue: 0 }),
    tipAmount: _emberData['default'].attr('number', { defaultValue: 0 }),
    surcharge: _emberData['default'].attr('number', { defaultValue: 0 }),
    account: _emberData['default'].attr('string'),

    caid: _emberData['default'].attr('string'),
    catid: _emberData['default'].attr('string'),
    posReference: _emberData['default'].attr('string'),
    authID: _emberData['default'].attr('string'),
    stan: _emberData['default'].attr('string'),
    settlementDate: _emberData['default'].attr('date'),
    truncatedPAN: _emberData['default'].attr('string'),
    cardName: _emberData['default'].attr('string'),
    businessName: _emberData['default'].attr('string'),
    businessABN: _emberData['default'].attr('string'),
    guid: _emberData['default'].attr('string'),
    receiptURL: _emberData['default'].attr('string'),

    notes: _emberData['default'].attr('string'),
    items: _emberData['default'].attr('string'), // stringified products

    products: _ember['default'].computed('items', {
      get: function get() /*key*/{
        var i = this.get('items');
        if (!i) {
          return [];
        }
        return JSON.parse(i);
      },
      set: function set(key, value) {
        this.set('items', JSON.stringify(value));
      }
    }),

    is_purchase: _ember['default'].computed('type', function () {
      return this.get('type') === 'purchase';
    })
  });
});