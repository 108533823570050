define("ppchrome/templates/history", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.2.0",
            "loc": {
              "source": null,
              "start": {
                "line": 8,
                "column": 8
              },
              "end": {
                "line": 8,
                "column": 120
              }
            },
            "moduleName": "ppchrome/templates/history.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "extra");
            var el2 = dom.createTextNode("Cash out ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 1, 1);
            return morphs;
          },
          statements: [["inline", "format-currency", [["get", "transaction.cashoutAmount", ["loc", [null, [8, 87], [8, 112]]]]], [], ["loc", [null, [8, 69], [8, 114]]]]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.2.0",
            "loc": {
              "source": null,
              "start": {
                "line": 9,
                "column": 8
              },
              "end": {
                "line": 9,
                "column": 107
              }
            },
            "moduleName": "ppchrome/templates/history.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "extra");
            var el2 = dom.createTextNode("Tip ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 1, 1);
            return morphs;
          },
          statements: [["inline", "format-currency", [["get", "transaction.tipAmount", ["loc", [null, [9, 78], [9, 99]]]]], [], ["loc", [null, [9, 60], [9, 101]]]]],
          locals: [],
          templates: []
        };
      })();
      var child2 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.2.0",
            "loc": {
              "source": null,
              "start": {
                "line": 10,
                "column": 8
              },
              "end": {
                "line": 10,
                "column": 113
              }
            },
            "moduleName": "ppchrome/templates/history.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "extra");
            var el2 = dom.createTextNode("Surcharge ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 1, 1);
            return morphs;
          },
          statements: [["inline", "format-currency", [["get", "transaction.surcharge", ["loc", [null, [10, 84], [10, 105]]]]], [], ["loc", [null, [10, 66], [10, 107]]]]],
          locals: [],
          templates: []
        };
      })();
      var child3 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.2.0",
            "loc": {
              "source": null,
              "start": {
                "line": 12,
                "column": 6
              },
              "end": {
                "line": 12,
                "column": 108
              }
            },
            "moduleName": "ppchrome/templates/history.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "notes");
            var el2 = dom.createElement("img");
            dom.setAttribute(el2, "src", "images/notes.png");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(" ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 2, 2);
            return morphs;
          },
          statements: [["content", "transaction.notes", ["loc", [null, [12, 81], [12, 102]]]]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.2.0",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 2
            },
            "end": {
              "line": 14,
              "column": 2
            }
          },
          "moduleName": "ppchrome/templates/history.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          var el3 = dom.createElement("img");
          dom.setAttribute(el3, "class", "status");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2, "class", "date");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "values");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "amount");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var element1 = dom.childAt(element0, [1]);
          var element2 = dom.childAt(element1, [0]);
          var element3 = dom.childAt(element0, [5]);
          var morphs = new Array(11);
          morphs[0] = dom.createAttrMorph(element0, 'class');
          morphs[1] = dom.createElementMorph(element0);
          morphs[2] = dom.createAttrMorph(element1, 'class');
          morphs[3] = dom.createAttrMorph(element2, 'src');
          morphs[4] = dom.createMorphAt(element1, 1, 1);
          morphs[5] = dom.createMorphAt(dom.childAt(element0, [3]), 0, 0);
          morphs[6] = dom.createMorphAt(dom.childAt(element3, [1]), 0, 0);
          morphs[7] = dom.createMorphAt(element3, 3, 3);
          morphs[8] = dom.createMorphAt(element3, 5, 5);
          morphs[9] = dom.createMorphAt(element3, 7, 7);
          morphs[10] = dom.createMorphAt(element0, 7, 7);
          return morphs;
        },
        statements: [["attribute", "class", ["concat", ["row ", ["subexpr", "if", [["subexpr", "eq", [["get", "active", ["loc", [null, [3, 66], [3, 72]]]], ["get", "transaction.id", ["loc", [null, [3, 73], [3, 87]]]]], [], ["loc", [null, [3, 62], [3, 88]]]], "active"], [], ["loc", [null, [3, 57], [3, 99]]]], " ", ["subexpr", "unless", [["get", "transaction.approved", ["loc", [null, [3, 109], [3, 129]]]], "declined"], [], ["loc", [null, [3, 100], [3, 142]]]]]]], ["element", "action", ["showReceipt", ["get", "transaction", ["loc", [null, [3, 32], [3, 43]]]]], [], ["loc", [null, [3, 9], [3, 45]]]], ["attribute", "class", ["concat", ["type ", ["get", "transaction.type", ["loc", [null, [4, 25], [4, 41]]]]]]], ["attribute", "src", ["concat", ["images/", ["subexpr", "if", [["get", "transaction.approved", ["loc", [null, [4, 82], [4, 102]]]], "tick", "cross"], [], ["loc", [null, [4, 77], [4, 119]]]], ".png"]]], ["content", "transaction.type", ["loc", [null, [4, 127], [4, 147]]]], ["inline", "moment-format", [["get", "transaction.datetime", ["loc", [null, [5, 41], [5, 61]]]]], [], ["loc", [null, [5, 25], [5, 63]]]], ["inline", "format-currency", [["get", "transaction.amount", ["loc", [null, [7, 46], [7, 64]]]]], [], ["loc", [null, [7, 28], [7, 66]]]], ["block", "if", [["get", "transaction.cashoutAmount", ["loc", [null, [8, 14], [8, 39]]]]], [], 0, null, ["loc", [null, [8, 8], [8, 127]]]], ["block", "if", [["get", "transaction.tipAmount", ["loc", [null, [9, 14], [9, 35]]]]], [], 1, null, ["loc", [null, [9, 8], [9, 114]]]], ["block", "if", [["get", "transaction.surcharge", ["loc", [null, [10, 14], [10, 35]]]]], [], 2, null, ["loc", [null, [10, 8], [10, 120]]]], ["block", "if", [["get", "transaction.notes", ["loc", [null, [12, 12], [12, 29]]]]], [], 3, null, ["loc", [null, [12, 6], [12, 115]]]]],
        locals: ["transaction"],
        templates: [child0, child1, child2, child3]
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.2.0",
          "loc": {
            "source": null,
            "start": {
              "line": 14,
              "column": 2
            },
            "end": {
              "line": 16,
              "column": 2
            }
          },
          "moduleName": "ppchrome/templates/history.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "empty");
          var el2 = dom.createElement("div");
          var el3 = dom.createTextNode("You haven't done any transactions.");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["multiple-nodes"]
        },
        "revision": "Ember@2.2.0",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 21,
            "column": 0
          }
        },
        "moduleName": "ppchrome/templates/history.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "history-list fill-height");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "history-record fill-height");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 1, 1);
        morphs[1] = dom.createMorphAt(dom.childAt(fragment, [2]), 1, 1);
        return morphs;
      },
      statements: [["block", "each", [["get", "model", ["loc", [null, [2, 10], [2, 15]]]]], [], 0, 1, ["loc", [null, [2, 2], [16, 11]]]], ["content", "outlet", ["loc", [null, [19, 2], [19, 12]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});