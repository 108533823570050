define('ppchrome/router', ['exports', 'ember', 'ppchrome/config/environment'], function (exports, _ember, _ppchromeConfigEnvironment) {

  var Router = _ember['default'].Router.extend({
    location: _ppchromeConfigEnvironment['default'].locationType
  });

  Router.map(function () {
    this.route('purchase');
    this.route('refund');
    this.route('settings');
    this.route('history', function () {
      this.route('record', { path: ':transaction_id' });
    });
    this.route('setup', function () {
      this.route('welcome');
    });
  });

  exports['default'] = Router;
});