define('ppchrome/controllers/setup', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    appAuth: _ember['default'].inject.service(),
    cloudEftpos: _ember['default'].inject.service(),
    localStore: _ember['default'].inject.service(),
    settings: _ember['default'].inject.service(),

    email: null,
    password: null,
    pos_name: null,

    step: 1,

    reset: function reset() {
      this.set('email', '');
      this.set('password', '');
      this.set('pos_name', '');
      this.set('step', 1);
    },

    step_welcome: _ember['default'].computed('step', function () {
      return this.get('step') === 1;
    }),
    step_get_started: _ember['default'].computed('step', function () {
      return this.get('step') === 2;
    }),
    step_authenticate: _ember['default'].computed('step', function () {
      return this.get('step') === 3;
    }),
    step_authenticated: _ember['default'].computed('step', function () {
      return this.get('step') === 4;
    }),
    step_choose_pinpad: _ember['default'].computed('step', function () {
      return this.get('step') === 5;
    }),

    actions: {
      back: function back() {
        this.set('step', this.get('step') - 1);
      },
      forward: function forward() {
        this.set('step', this.get('step') + 1);
      },
      tell_account: function tell_account() {
        window.open("http://www.questpaymentsystems.com");
      },
      tell_pinpad: function tell_pinpad() {
        window.open("http://www.questpaymentsystems.com");
      },
      authenticate: function authenticate() {
        var _this = this;

        var sdk = this.get('cloudEftpos.sdk');
        var email = this.get('email');
        var password = this.get('password');
        var pos_name = this.get('pos_name');
        var loading = sdk.loading("Please wait...", "Authenticating");
        sdk.fullAuth(email, password, pos_name, function (authorised, error) {
          loading.close();
          if (!authorised) {
            sdk.alert(error, "Authentication Failed");
            return;
          }
          _this.set('settings.posDetails', {
            'user': email,
            'posname': pos_name,
            'company': ''
          });
          _this.get('appAuth').setAuthed();
          _this.actions.forward.call(_this);
        });
      },
      done: function done() {
        this.transitionToRoute('purchase');
      },
      choose_pinpad: function choose_pinpad() {
        this.transitionToRoute('purchase');
        var sdk = this.get('cloudEftpos.sdk');
        sdk.pairDevice(true, function () {
          // Pairing screen closed
        });
      },
      help_device_name: function help_device_name() {
        this.get('cloudEftpos.sdk').alert("The device name should be the name of this computer so you can differentiate between installations of Pocket Pay.", "Device Name");
      },
      help_serial_number: function help_serial_number() {
        this.get('cloudEftpos.sdk').alert("Your serial number can be found on the back of your PINpad, or by pressing the Enter key if you're using an MT330.", "Serial Number");
      }
    }
  });
});