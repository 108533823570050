define('ppchrome/components/receipt-display', ['exports', 'ember', 'ppchrome/helpers/receipt-products'], function (exports, _ember, _ppchromeHelpersReceiptProducts) {
  exports['default'] = _ember['default'].Component.extend({
    cloudEftpos: _ember['default'].inject.service(),
    printer: _ember['default'].inject.service(),
    settings: _ember['default'].inject.service(),

    back: false, // Display a back button

    classNames: ["history-record"],

    send_email: function send_email() {
      var _this = this;

      var transaction = this.get('model');
      var sdk = this.get('cloudEftpos.sdk');
      sdk.prompt("Enter an email address", null, true, ["Cancel", "Send"], function (key, email) {
        if (key === "Cancel") {
          return;
        }
        var re = /\S+@\S+\.\S+/;
        if (!re.test(email)) {
          sdk.alert("Please enter a valid email address.", null, null, function () {
            _this.send_email();
          });
          return;
        }
        sdk.emailReceipt(transaction.get('posReference'), transaction.get('guid'), email);
        sdk.alert("Email sent.");
      });
    },

    actions: {
      email: function email() {
        this.send_email();
      },
      print: function print() {
        var receipt = this.get('model.receipt');
        var products = (0, _ppchromeHelpersReceiptProducts.receiptProducts)([this.get('model.products'), 24]);
        var notes = this.get('model.notes');
        var print = [];
        if (products && this.get('settings.print_products')) {
          print.push(products);
        }
        if (receipt) {
          print.push(receipt);
        }
        if (notes && this.get('settings.print_notes')) {
          print.push(notes);
        }
        if (!print.length) {
          return;
        }
        print = print.join('\r\n\r\n' + "-".repeat(24) + '\r\n\r\n');
        this.get('printer').print(print);
      }
    }
  });
});