define('ppchrome/services/local-store', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Service.extend({
    storeValue: function storeValue(key, value) {
      if (typeof chrome !== 'undefined' && chrome.storage !== undefined) {
        var data = {};
        data[key] = value;
        chrome.storage.local.set(data);
      } else {
        localStorage[key] = JSON.stringify(value);
      }
    },

    getValue: function getValue(key, onComplete) {
      if (typeof chrome !== 'undefined' && chrome.storage !== undefined) {
        chrome.storage.local.get(key, function (items) {
          onComplete(items[key]);
        });
      } else {
        if (localStorage[key] === undefined) {
          onComplete(undefined);
          return;
        }
        onComplete(JSON.parse(localStorage[key]));
      }
    }
  });
});