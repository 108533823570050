define('ppchrome/services/printer', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Service.extend({
    settings: _ember['default'].inject.service(),
    cloudEftpos: _ember['default'].inject.service(),

    serial_port: '',
    serial_connection_id: false,
    serial_data: '',
    serial_send_timer: false,

    serial_connection_options: {
      bitrate: 115200
    },

    serial_port_changed: _ember['default'].observer('settings.serial_printer_port', 'settings.serial_printer', function () {
      var new_port = this.get('settings.serial_printer_port');
      if (this.serial_connection_id !== false && (new_port !== this.get('serial_port') || this.get('settings.serial_printer') === false)) {
        this.serial_disconnect();
      }
      this.set('serial_port', new_port);
    }),

    serial_printer_enabled: function serial_printer_enabled() {
      return this.get('settings.serial_printer') && this.get('settings.serial_printer_port') !== '';
    },

    init: function init() {
      var _this = this;

      if (this.get('settings.is_chrome_app')) {
        chrome.serial.onReceive.addListener(function (info) {
          if (info.connectionId === _this.serial_connection_id) {
            // Ignore response
          }
        });
        chrome.serial.onReceiveError.addListener(function (info) {
          if (info.connectionId !== _this.serial_connection_id) {
            return;
          }
          _this.serial_disconnect();
        });
      }

      if (this.serial_printer_enabled()) {
        setTimeout(function () {
          _this.serial_connect();
        }, 500);
      }
    },
    serial_connect: function serial_connect() {
      var _this2 = this;

      var onComplete = arguments.length <= 0 || arguments[0] === undefined ? function () {} : arguments[0];

      chrome.serial.connect(this.get('settings.serial_printer_port'), this.serial_connection_options, function (connectionInfo) {
        if (chrome.runtime.lastError) {
          console.log("Printer - " + chrome.runtime.lastError.message);
          onComplete(false, chrome.runtime.lastError.message);
          return;
        }
        _this2.serial_connection_id = connectionInfo.connectionId;
        onComplete(true);
      });
    },
    serial_disconnect: function serial_disconnect() {
      if (this.serial_connection_id === false) {
        return;
      }
      chrome.serial.disconnect(this.serial_connection_id, function () {});
      this.serial_connection_id = false;
      this.serial_data = '';
    },
    serial_send: function serial_send(data) {
      var _this3 = this;

      this.serial_data += data;
      if (this.serial_send_timer !== false) {
        return;
      }

      this.serial_send_timer = setInterval(function () {
        if (_this3.serial_data.length === 0 || _this3.serial_connection_id === false) {
          clearInterval(_this3.serial_send_timer);
          _this3.serial_send_timer = false;
          return;
        }
        var portion = _this3.serial_data.slice(0, 128);
        _this3.serial_data = _this3.serial_data.slice(128);
        var buffer = _this3._toArrayBuffer(portion);
        chrome.serial.send(_this3.serial_connection_id, buffer, function (sendInfo) {
          // Ignore response
          if (sendInfo.error) {
            _this3.serial_disconnect();
          }
        });
      }, 200);
    },

    print: function print(data) {
      var _this4 = this;

      if (this.serial_printer_enabled()) {
        data += "\n\n\n\n\n\n";
        if (this.serial_connection_id === false) {
          this.serial_connect(function (success, error) {
            if (success) {
              _this4.serial_send(data);
              return;
            }
            // Error, lets display a message
            _this4.get('cloudEftpos.sdk').alert(error + ('<br />(Port ' + _this4.get('settings.serial_printer_port') + ')'), "Printer Error");
          });
          return;
        }
        // Else just send the message
        this.serial_send(data);
        return;
      }

      // Fallback to standard Windows printing
      if (this.get('settings.is_chrome_app')) {
        chrome.app.window.create('/receipt.html', { innerBounds: { width: 600, height: 480 } }, function (w) {
          w.contentWindow.onload = function () {
            w.contentWindow.document.getElementById("receipt").innerHTML = data;
            w.contentWindow.print();
            setTimeout(function () {
              w.close();
            }, 10);
          };
        });
      } else {
        (function () {
          var printWindow = window.open('/receipt.html', 'Printing', 'height=480,width=600');
          setTimeout(function () {
            printWindow.document.getElementById("receipt").innerHTML = data;
            printWindow.focus();
            printWindow.print();
            setTimeout(function () {
              printWindow.close();
            }, 10);
          }, 100);
        })();
      }
    },
    _toArrayBuffer: function _toArrayBuffer(data) {
      var length = data.length;
      var buffer = new ArrayBuffer(length * 2);
      var view = new Uint16Array(buffer);
      for (var i = 0; i < length; i++) {
        view[i] = data.charCodeAt(i);
      }
      return buffer;
    },
    _fromArrayBuffer: function _fromArrayBuffer(data) {
      return String.fromCharCode.apply(null, new Uint16Array(data));
    }
  });
});