define('ppchrome/routes/setup', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    appAuth: _ember['default'].inject.service(),

    setupController: function setupController(controller /*, model*/) {
      this._super.apply(this, arguments);
      controller.reset();
    },

    beforeModel: function beforeModel() {
      if (this.get('appAuth.is_authed') === true) {
        this.transitionTo('purchase');
      }
    }
  });
});