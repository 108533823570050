define('ppchrome/controllers/application', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    appAuth: _ember['default'].inject.service(),
    cloudEftpos: _ember['default'].inject.service(),
    toast: _ember['default'].inject.service(),
    updater: _ember['default'].inject.service(),
    localStore: _ember['default'].inject.service(),
    settings: _ember['default'].inject.service(),

    expand_menu: true,
    fullscreen: false,

    init: function init() {
      var _this = this;

      if (this.get('settings.is_chrome_app')) {
        this.set('fullscreen', chrome.app.window.current().isFullscreen());
      }
      // Override default expand menu if stored
      this.get('localStore').getValue('expand_menu', function (expand_menu) {
        if (expand_menu === false) {
          _this.set('expand_menu', false);
        }
      });
    },

    display_menu: _ember['default'].computed('currentPath', function () {
      return this.get('currentPath') !== 'setup.index';
    }),

    showConnecting: _ember['default'].observer('cloudEftpos.connected', function () {
      var connected = this.get('cloudEftpos.connected');
      var pinpadName = this.get('cloudEftpos.pinpadName');
      // Show connected or disconnect toast
      if (connected) {
        this.get('toast').info('Connecting to PINpad', 'Connecting');
      } else {
        this.get('toast').info('Disconnected from #' + pinpadName, 'Disconnected');
      }
    }),
    showReady: _ember['default'].observer('cloudEftpos.ready', function () {
      var ready = this.get('cloudEftpos.ready');
      var pinpadName = this.get('cloudEftpos.pinpadName');
      if (ready) {
        this.get('toast').info('Connected to PINpad #' + pinpadName, 'Connected');
      }
    }),
    stateImage: _ember['default'].computed('cloudEftpos.ready', 'cloudEftpos.connected', 'cloudEftpos.paired', function () {
      var ready = this.get('cloudEftpos.ready');
      var connected = this.get('cloudEftpos.connected');
      var paired = this.get('cloudEftpos.paired');
      if (ready) {
        return "pinpad-ready.png";
      }
      if (connected) {
        return "pinpad-connected.gif";
      }
      if (paired) {
        return "pinpad-searching.gif";
      }
      return "pinpad-not-paired.gif";
    }),
    state: _ember['default'].computed('cloudEftpos.ready', 'cloudEftpos.connected', 'cloudEftpos.paired', function () {
      var ready = this.get('cloudEftpos.ready');
      var connected = this.get('cloudEftpos.connected');
      var paired = this.get('cloudEftpos.paired');
      if (ready) {
        return "Connected";
      }
      if (connected) {
        return "Connecting...";
      }
      if (paired) {
        return "Searching...";
      }
      return "Choose PINpad";
    }),

    actions: {
      pairing: function pairing() {
        var sdk = this.get('cloudEftpos.sdk');
        if (this.get('cloudEftpos.paired')) {
          // Prompt for unpairing
          sdk.prompt("Unpair", 'Are you sure you want to unpair from PINpad #' + this.get('cloudEftpos.pinpadName'), false, ["Cancel", "Ok"], function (button) {
            if (button === "Ok") {
              sdk.unpairDevice();
            }
          });
          return;
        }
        // else show pairing screen
        sdk.pairDevice(true, function () {
          // Pairing screen closed
        });
      },
      tools: function tools() {
        this.get('cloudEftpos').sdk.advancedMenu(true, function () {
          // Menu closed
        }, function () /*type, receipt*/{
          // Print a receipt
        });
      },
      sendFeedback: function sendFeedback() {
        var pinpad = this.get('cloudEftpos.pinpadName');
        if (!pinpad) {
          pinpad = '';
        }
        var appname = this.get('settings.appname');
        var link = 'mailto:mpfeedback@questps.com.au?subject=' + escape(appname + ' Feedback') + '&body=' + escape('\n\nFeedback from -\nCompany: ' + this.get('settings.posDetails.company') + '\nDevice: ' + this.get('settings.posDetails.posname') + '\nUser: ' + this.get('settings.posDetails.user') + '\nPINpad: ' + pinpad + '\nApp: ' + appname + ' v' + this.get('settings.version') + ' sdk' + this.get('settings.sdk_version') + '\n');
        window.open(link);
      },
      cloudeftpos: function cloudeftpos() {
        if (this.get('settings.prod_mode')) {
          window.open('http://cloudeftpos.com/');
        } else {
          window.open('http://test.cloudeftpos.com/');
        }
      },
      help: function help() {
        window.open('http://mphelp.questps.com.au/');
      },
      toggleMenu: function toggleMenu() {
        this.toggleProperty('expand_menu');
        this.get('localStore').storeValue('expand_menu', this.get('expand_menu'));
      },
      completeUpdate: function completeUpdate() {
        this.get('updater').reload();
      },
      ignoreUpdate: function ignoreUpdate() {
        this.set('updater.updateAvailable', false);
      },
      close: function close() {
        if (this.get('settings.is_chrome_app')) {
          chrome.app.window.current().close();
        } else {
          window.close();
        }
      },
      fullscreen: function fullscreen() {
        this.set('fullscreen', true);
        if (this.get('settings.is_chrome_app')) {
          chrome.app.window.current().fullscreen();
        } else {
          if (document.body.requestFullscreen) {
            document.body.requestFullscreen();
          } else if (document.body.mozRequestFullScreen) {
            document.body.mozRequestFullScreen();
          } else if (document.body.webkitRequestFullscreen) {
            document.body.webkitRequestFullscreen();
          } else if (document.body.msRequestFullscreen) {
            document.body.msRequestFullscreen();
          }
        }
      },
      restore: function restore() {
        this.set('fullscreen', false);
        if (this.get('settings.is_chrome_app')) {
          chrome.app.window.current().restore();
        } else {
          if (document.exitFullscreen) {
            document.exitFullscreen();
          } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
          } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
          }
        }
      }
    }
  });
});