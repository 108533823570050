define('ppchrome/helpers/receipt-products', ['exports', 'ember', 'ppchrome/helpers/format-currency'], function (exports, _ember, _ppchromeHelpersFormatCurrency) {
  exports.receiptProducts = receiptProducts;

  function receiptProducts(params /*, hash*/) {
    var products = params[0];
    var width = params[1];
    var out = [];
    var space = " ";
    if (!Array.isArray(products)) {
      return "";
    }
    products.forEach(function (product) {
      var amount = (0, _ppchromeHelpersFormatCurrency.formatCurrency)([product.value]);
      var name = product.name;
      while (name.length > width) {
        out.push(name.splice(0, width));
      }
      if (name.length + space.length + amount.length > width) {
        out.push(name);
        name = "";
      }
      var paddedAmount = space.repeat(width - name.length - amount.length) + amount;
      out.push(name + paddedAmount);
    });
    return out.join('\r\n');
  }

  exports['default'] = _ember['default'].Helper.helper(receiptProducts);
});