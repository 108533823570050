define('ppchrome/controllers/tender-base', ['exports', 'ember', 'ppchrome/helpers/receipt-products'], function (exports, _ember, _ppchromeHelpersReceiptProducts) {
  exports['default'] = _ember['default'].Controller.extend({
    cloudEftpos: _ember['default'].inject.service(),
    localStore: _ember['default'].inject.service(),
    settings: _ember['default'].inject.service(),
    printer: _ember['default'].inject.service(),
    signaturePrinter: _ember['default'].inject.service(),

    tab: 'amount',
    type: TransactionRequest.PURCHASE,
    products_toggle: false,

    amount: 0,
    cashout: 0,
    items: [],
    notes: "",

    current_product: false,
    delete_toggle: false,

    current_record: false,

    reset: function reset() {
      this.set('items', []);
      this.set('products_toggle', this.get('settings.display_products_default'));
      this.set('amount', 0);
      this.set('cashout', 0);
      this.set('notes', "");
      this.set('tab', 'amount');
    },

    buttons: _ember['default'].computed('settings.product_buttons', function () {
      return this.get('settings.product_buttons').map(function (button) {
        return {
          name: button.name,
          price: button.price * 100
        }; // Copy objects so as not to pollute them
      });
    }),

    hasProducts: _ember['default'].computed('buttons', function () {
      return this.get('buttons').length > 0;
    }),
    isRefund: _ember['default'].computed('type', function () {
      return this.get('type') === TransactionRequest.REFUND;
    }),
    showCashout: _ember['default'].computed('type', 'settings.allow_cashout', function () {
      return this.get('settings.allow_cashout') && !this.get('isRefund');
    }),
    total: _ember['default'].computed('amount', 'cashout', 'items', function () {
      return this.get('cashout') + this.get('amount') + this.get('items').reduce(function (value, item) {
        return value + item.value;
      }, 0);
    }),
    amountTabActive: _ember['default'].computed('tab', function () {
      return this.get('tab') === 'amount';
    }),
    cashoutTabActive: _ember['default'].computed('tab', function () {
      return this.get('tab') === 'cashout';
    }),
    displayProducts: _ember['default'].computed('amountTabActive', 'products_toggle', 'displayModalCalculator', function () {
      return this.get('amountTabActive') && this.get('products_toggle') && !this.get('displayModalCalculator');
    }),
    displayAmountCalculator: _ember['default'].computed('amountTabActive', 'products_toggle', 'displayModalCalculator', function () {
      return this.get('amountTabActive') && !this.get('products_toggle') && !this.get('displayModalCalculator');
    }),
    displayCashoutCalculator: _ember['default'].computed('cashoutTabActive', function () {
      return this.get('cashoutTabActive') && !this.get('displayModalCalculator');
    }),
    displayModalCalculator: _ember['default'].computed('current_product', function () {
      return this.get('current_product');
    }),

    finalize: function finalize() {
      var amount_calculator = this.get('amount_calculator');
      var cashout_calculator = this.get('cashout_calculator');
      if (amount_calculator) {
        amount_calculator.send('equals');
      }
      if (this.get('showCashout') && cashout_calculator) {
        cashout_calculator.send('equals');
      }
    },
    clear: function clear() {
      var amount_calculator = this.get('amount_calculator');
      var cashout_calculator = this.get('cashout_calculator');
      if (amount_calculator) {
        amount_calculator.clear(true);
      }
      if (cashout_calculator) {
        cashout_calculator.clear(true);
      }
      this.reset();
    },

    actions: {
      amountTab: function amountTab() {
        this.finalize();
        this.set('tab', 'amount');
      },
      cashoutTab: function cashoutTab() {
        this.finalize();
        this.set('tab', 'cashout');
      },
      toggleProducts: function toggleProducts() {
        this.toggleProperty('products_toggle');
      },
      clearNotes: function clearNotes() {
        this.set('notes', '');
      },
      clearList: function clearList() {
        this.set('items', []);
        this.get('amount_calculator').clear(true);
        this.get('cashout_calculator').clear(true);
      },
      tender: function tender() {
        var _this = this;

        this.finalize();
        var sdk = this.get('cloudEftpos.sdk');
        var cloudEftpos = this.get('cloudEftpos');

        // Validate details
        var amount = this.get('amount') + this.get('items').reduce(function (value, item) {
          return value + item.value;
        }, 0);
        var cashout = this.get('cashout');
        if (!cloudEftpos.get('connected') || !cloudEftpos.get('ready')) {
          sdk.alert("A PINpad is not connected.");
          return;
        }
        if (amount < 0 || cashout < 0 || amount + cashout <= 0) {
          sdk.alert("The tender value must be greater than $0.00.");
          return;
        }
        if (cashout % 5 !== 0) {
          sdk.alert("The cashout value must be a multiple of 5c.");
          return;
        }

        // Get pos ref and do transaction
        var localStore = this.get('localStore');
        localStore.getValue('lastPosReference', function (posReference) {
          if (!posReference) {
            posReference = 1;
          } else {
            posReference++;
          }
          localStore.storeValue('lastPosReference', posReference);
          posReference = posReference * 10 + 1; // Convert to use proper format

          var request = new TransactionRequest(_this.get('type'), posReference, amount);
          request.cashOut = cashout;
          request.posNotes = _this.get('notes');
          request.autoGpsLocation = _this.get('settings.record_location');
          if (_this.get('type') === TransactionRequest.REFUND) {
            request.verifyRefund = true;
          }

          // Create record and in progress
          var items = $.extend(true, [], _this.get('items'));
          var misc = _this.get('amount');
          if (misc) {
            items.splice(0, 0, {
              name: 'Miscellaneous',
              value: misc
            });
          }
          if (cashout) {
            items.push({
              name: 'Cashout',
              value: cashout
            });
          }
          var record = cloudEftpos.create_record(request, items);
          record.save().then(function () {
            localStore.storeValue('inProgress', {
              id: record.get('id'),
              posReference: posReference,
              type: _this.get('type')
            });

            // Add on product info to POS notes for cloud eftpos
            request.posNotes += (request.posNotes !== "" ? "\r\n\r\n" : "") + (0, _ppchromeHelpersReceiptProducts.receiptProducts)([items, 24]);

            sdk.startTransactionExtended(request, null, null, function (receipt, keys) {
              _this.get('signaturePrinter').go(receipt, keys);
            }, true, function (results, error) {
              cloudEftpos.store_result(record, results, error);
              // Clear in progress
              localStore.storeValue('inProgress', false);
              if (error) {
                sdk.alert(error.message, null, "Ok");
                return;
              }
              if (results.businessName) {
                _this.updateBusinessName(results.businessName);
              }
              var title = results.approved ? "Approved" : "Declined";
              if (results.receipt) {
                _this.set('current_record', record);
                if (_this.get('settings.always_print')) {
                  var receipt = results.receipt;
                  if (_this.get('settings.print_notes')) {
                    receipt += "\r\n\r\n" + _this.get('model.notes');
                  }
                  _this.get('printer').print(receipt);
                }
              } else {
                sdk.alert(results.responseText + ' (' + results.responseCode + ')', title);
              }
              if (results.approved) {
                _this.clear();
              }
            });
          });
        });
      },
      receipt_close: function receipt_close() {
        this.set('current_record', false);
      },
      add_product: function add_product(button) {
        if (this.get('current_product')) {
          this.set('current_product', false);
          if (button === false) {
            return;
          }
        } else if (button.price <= 0) {
          this.set('current_product', button);
          return;
        }
        var items = this.get('items');
        items.push({
          name: button.name,
          value: button.price
        });
        this.set('items', items);
        this.notifyPropertyChange('items');

        _ember['default'].run.scheduleOnce('afterRender', function () {
          var list = $('.purchase-list .list')[0];
          list.scrollTop = list.scrollHeight;
        });
        return;
      },
      remove_product: function remove_product(index) {
        var items = this.get('items');
        items.splice(index, 1);
        this.set('items', items);
        this.set('delete_toggle', false);
        this.notifyPropertyChange('items');
      },
      toggle_product_delete: function toggle_product_delete(index) {
        var toggle = this.get('delete_toggle');
        if (index === toggle) {
          index = false;
        }
        this.set('delete_toggle', index);
      }
    },

    updateBusinessName: function updateBusinessName(businessName) {
      var posDetails = this.get('settings.posDetails');
      posDetails['company'] = businessName;
      this.set('settings.posDetails', posDetails);
    }
  });
});