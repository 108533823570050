define('ppchrome/services/signature-printer', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Service.extend({
    settings: _ember['default'].inject.service(),
    printer: _ember['default'].inject.service(),
    cloudEftpos: _ember['default'].inject.service(),

    init: function init() {},

    go: function go(receipt, keys) {
      this.set('receipt', receipt);
      this.set('keys', keys);
      if (this.get('settings.always_print')) {
        this.doPrint();
      } else {
        this.doPrompt();
      }
    },

    doPrint: function doPrint() {
      var _this = this;

      var sdk = this.get('cloudEftpos.sdk');
      var receipt = this.get('receipt');
      var keys = this.get('keys');
      this.get('printer').print(receipt);
      sdk.prompt("Signature Required", "Does the customers signature match?", false, ["Yes", "No", "Reprint", "Sign on Screen", "Cancel"], function (key, input) {
        switch (key) {
          case "Yes":
          case "No":
          case "Cancel":
            sdk.promptResponse(key.toLowerCase());
            break;
          case "Reprint":
            _this.doPrint();
            break;
          case "Sign on Screen":
            sdk.signatureFallback(receipt, keys);
            break;
        }
      }, true);
    },

    doPrompt: function doPrompt() {
      var _this2 = this;

      var sdk = this.get('cloudEftpos.sdk');
      var receipt = this.get('receipt');
      var keys = this.get('keys');
      sdk.prompt("Signature Required", "Does the customers signature match?", false, ["Print Signature Receipt", "Sign on Screen", "Cancel"], function (key, input) {
        switch (key) {
          case "Cancel":
            sdk.promptResponse(key.toLowerCase());
            break;
          case "Print Signature Receipt":
            _this2.doPrint();
            break;
          case "Sign on Screen":
            sdk.signatureFallback(receipt, keys);
            break;
        }
      }, true);
    }
  });
});