define('ppchrome/services/updater', ['exports', 'ember', 'ppchrome/config/environment'], function (exports, _ember, _ppchromeConfigEnvironment) {
  exports['default'] = _ember['default'].Service.extend({
    cloudEftpos: _ember['default'].inject.service(),
    localStore: _ember['default'].inject.service(),
    settings: _ember['default'].inject.service(),

    updateAvailable: false,

    init: function init() {
      var _this = this;

      if (typeof chrome === 'undefined' || !chrome.runtime || !chrome.runtime.onUpdateAvailable) {
        return;
      }
      chrome.runtime.onUpdateAvailable.addListener(function (details) {
        _this.set('updateAvailable', details.version);
      });
      var localStore = this.get('localStore');
      var version = this.get('settings.version');
      localStore.getValue('appVersion', function (oldVersion) {
        if (!oldVersion) {
          localStore.storeValue('appVersion', version);
          return;
        }
        if (oldVersion !== version) {
          var changes = _ppchromeConfigEnvironment['default'].changes;
          var changelist = "";
          if (changes) {
            // Have to mess around with HTML a bit here to correctly lay things out in our alert box
            changelist = '</p><p>Changes:</p><ul class="shrink-top"><li>' + changes.join("</li><li>") + '</li></ul><p>';
          }
          _this.get('cloudEftpos.sdk').alert('You\'ve upgraded to version ' + version + '.' + changelist, 'Upgrade Complete');
          localStore.storeValue('appVersion', version);
        }
      });
    },

    reload: function reload() {
      chrome.runtime.reload();
    }
  });
});