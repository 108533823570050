define('ppchrome/services/app-auth', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Service.extend({
    localStore: _ember['default'].inject.service(),

    is_authed: true, // Will be overridden on init

    init: function init() {
      var _this = this;

      this.get('localStore').getValue('is_authed', function (is_authed) {
        if (!is_authed) {
          is_authed = false; // Get rid of any null/undefined values
        }
        _this.set('is_authed', is_authed);
      });
    },

    setAuthed: function setAuthed() {
      var authed = arguments.length <= 0 || arguments[0] === undefined ? true : arguments[0];

      this.get('localStore').storeValue('is_authed', authed);
      this.set('is_authed', authed);
    }
  });
});