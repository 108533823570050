define('ppchrome/controllers/history', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    cloudEftpos: _ember['default'].inject.service(),

    active: null,

    reset: function reset() {
      this.set('active', null);
    },

    actions: {
      showReceipt: function showReceipt(transaction) {
        var _this = this;

        var sdk = this.get('cloudEftpos.sdk');
        if (transaction.get('receipt') || transaction.get('products').length || transaction.get('notes')) {
          this.transitionToRoute('history.record', transaction).then(function () {
            _this.set('active', transaction.get('id'));
          });
        }
        if (!transaction.get('receipt')) {
          var text = transaction.get('responseText');
          var code = transaction.get('responseCode');
          var message = transaction.get('message');
          if (text) {
            message = text + ' (' + code + ')';
          }
          sdk.alert(message, 'Declined');
        }
      }
    }
  });
});