define('ppchrome/components/tender-calculator', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNameBindings: ['hidden'],

    hidden: _ember['default'].computed('visible', function () {
      return !this.get('visible');
    }),

    amount: '0',
    lastAmount: 0,
    calcText: '',
    dplaces: -1,
    isRefund: false,
    modal: false,
    equaled: false,

    max_amount: 999999.99,

    amountValue: 0,
    title: '',

    plusEnabled: false,
    minusEnabled: false,

    _registerAs: function _registerAs() {
      this.set('register-as', this); // register-as is a new property
    },
    didRender: function didRender() {
      this._super.apply(this, arguments);
      _ember['default'].run.scheduleOnce('afterRender', this, '_registerAs');
    },
    finalize: function finalize() {
      this.actions.equals.call(this);
    },

    displayAmount: _ember['default'].computed('amount', function () {
      var amount = this.get('amount');
      if (amount === '0') {
        return "0.00";
      }
      return this.get('amount');
    }),
    total: _ember['default'].computed('amount', 'lastAmount', function () {
      var operator = 1;
      if (this.get('minusEnabled')) {
        operator = -1;
      }
      var total = operator * Number(this.get('amount')) + this.get('lastAmount');
      if (total < 0) {
        total = 0;
      }
      return '$ ' + total.toFixed(2);
    }),
    amountIsEmpty: _ember['default'].computed('amount', function () {
      return this.get('amount') === '0';
    }),

    actions: {
      addDigit: function addDigit(value) {
        var amount = this.get('amount');
        var dplaces = this.get('dplaces');
        if (amount === '0' && value !== '.') {
          if (value === 0) {
            return; // Can't keep pressing 0
          }
          amount = String(value);
        } else {
          if (value === '.') {
            if (dplaces > -1) {
              return; // Can't have more than one decimal place
            }
            dplaces += 1;
          } else if (dplaces > -1) {
            if (dplaces >= 2) {
              return; // Only up to 2 decimal places
            }
            dplaces += 1;
          }
          amount += String(value);
        }
        if (Number(amount) > this.get('max_amount')) {
          return;
        }
        this.set('amount', amount);
        this.set('dplaces', dplaces);
        this.updateAmountValue();
      },
      'delete': function _delete() {
        var amount = this.get('amount');
        var dplaces = this.get('dplaces');
        if (amount !== '0') {
          amount = amount.substring(0, amount.length - 1);
          if (dplaces > -1) {
            dplaces -= 1;
          }
        }
        if (amount === "") {
          amount = '0';
        }
        this.set('amount', amount);
        this.set('dplaces', dplaces);
        this.updateAmountValue();
      },
      clear: function clear() {
        this.clear(true);
      },
      operator: function operator(mode) {
        var _this = this;

        var calcText = this.get('calcText');
        var amount = this.get('amount');
        var lastAmount = this.get('lastAmount');
        var operator = 1;
        if (this.get('minusEnabled')) {
          operator = -1;
        }
        if (amount === '0' && lastAmount === 0) {
          return;
        }
        this.clear();
        if (amount === '0') {
          if (calcText.length > 1) {
            var extra = '<br />' + mode + ' ';
            calcText = calcText.substring(0, calcText.length - extra.length) + extra;
          }
        } else {
          calcText += Number(amount).toFixed(2) + '<br />' + mode + ' ';
        }
        lastAmount += operator * Number(amount);
        this.set('amount', '0');
        this.set('lastAmount', lastAmount);
        this.set(mode === '+' ? 'plusEnabled' : 'minusEnabled', true);
        this.set('calcText', calcText);
        this.updateAmountValue();

        _ember['default'].run.scheduleOnce('afterRender', function () {
          var list = _this.$('.running-total')[0];
          list.scrollTop = list.scrollHeight;
        });
      },
      equals: function equals() {
        var amount = this.get('amount');
        var operator = 1;
        if (this.get('minusEnabled')) {
          operator = -1;
        }
        amount = (operator * Number(amount) + this.get('lastAmount')).toFixed(2);
        if (Number(amount) <= 0) {
          this.clear();
          return;
        }
        this.clear();
        this.set('amount', amount);
        this.set('dplaces', 2);
        this.updateAmountValue();
        this.set('equaled', true);
      },
      done: function done() {
        this.sendAction('add_product', {
          name: this.get('title'),
          price: Math.round(Number(this.get('amount') * 100))
        });
      },
      cancel: function cancel() {
        this.sendAction('add_product', false);
      }
    },

    clear: function clear() {
      var updateAmountValue = arguments.length <= 0 || arguments[0] === undefined ? false : arguments[0];

      this.set('amount', '0');
      this.set('lastAmount', 0);
      this.set('dplaces', -1);
      this.set('calcText', '');
      this.set('plusEnabled', false);
      this.set('minusEnabled', false);
      if (updateAmountValue) {
        this.updateAmountValue();
      }
    },
    updateAmountValue: function updateAmountValue() {
      this.set('equaled', false);
      var operator = 1;
      if (this.get('minusEnabled')) {
        operator = -1;
      }
      var newValue = operator * Number(this.get('amount')) * 100 + this.get('lastAmount') * 100;
      if (newValue < 0) {
        newValue = 0;
      }
      this.set('amountValue', Math.round(newValue));
    }
  });
});