define('ppchrome/services/settings', ['exports', 'ember', 'ppchrome/config/environment'], function (exports, _ember, _ppchromeConfigEnvironment) {
  exports['default'] = _ember['default'].Service.extend({
    localStore: _ember['default'].inject.service(),

    // Static
    appname: _ppchromeConfigEnvironment['default'].appname,
    version: _ppchromeConfigEnvironment['default'].version,
    sdk_version: CloudEftposSDK.version,
    prod_mode: _ppchromeConfigEnvironment['default'].environment === "production",

    is_chrome_app: typeof chrome !== 'undefined' && chrome.app && chrome.app.window,
    is_windows: navigator.platform.indexOf('Win') > -1,

    training_mode: _ember['default'].computed('posDetails.user', function () {
      return this.get('posDetails.user') === 'test';
    }),

    // Defaults
    values: {
      allow_cashout: true,
      record_location: true,
      always_print: false,
      print_products: false,
      print_notes: false,
      serial_printer: false,
      serial_printer_port: '',
      product_buttons: [],
      display_products_default: false,
      posDetails: {},
      use_whistle_comms: false
    },

    get_set: function get_set(value_name) {
      return {
        get: function get() /*key*/{
          return this.get('values.' + value_name);
        },
        set: function set(key, value) {
          this.set('values.' + value_name, value);
          this.get('localStore').storeValue('settings', this.get('values'));
          return value;
        }
      };
    },

    init: function init() {
      var _this = this;

      // Setup computed properties
      Object.keys(this.get('values')).forEach(function (key) {
        _this[key] = _ember['default'].computed('values.' + key, _this.get_set(key));
      });

      this.get('localStore').getValue('settings', function (settings) {
        console.log("SETTINGS NOW LOADED!");
        if (!settings) {
          return;
        }
        Object.keys(settings).forEach(function (property) {
          _this.set('values.' + property, settings[property]);
        });
      });
    }
  });
});