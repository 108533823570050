define('ppchrome/routes/history', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      // Use query to force full fetch
      return this.store.query('transaction', {}).then(function (transactions) {
        return transactions.toArray().sortBy('datetime').reverseObjects();
      });
    },
    actions: {
      didTransition: function didTransition() {
        this.controller.reset();
        return true;
      }
    }
  });
});