define('ppchrome/routes/refund', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    cloudEftpos: _ember['default'].inject.service(),

    setupController: function setupController(controller /*, model*/) {
      this._super.apply(this, arguments);
      controller.reset();
    },
    renderTemplate: function renderTemplate() {
      this.render('tender-base', {
        controller: 'refund'
      });
    }
  });
});