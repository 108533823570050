define('ppchrome/routes/application', ['exports', 'ember', 'ppchrome/config/environment'], function (exports, _ember, _ppchromeConfigEnvironment) {
  exports['default'] = _ember['default'].Route.extend({
    appAuth: _ember['default'].inject.service(),
    cloudEftpos: _ember['default'].inject.service(),
    updater: _ember['default'].inject.service(),

    authChanged: _ember['default'].observer('appAuth.is_authed', function () {
      if (!this.get('appAuth.is_authed')) {
        this.transitionTo('setup');
      }
    }),

    beforeModel: function beforeModel() {
      window.APPLICATION_NAME = _ppchromeConfigEnvironment['default'].appname;
      window.APPLICATION_VERSION = _ppchromeConfigEnvironment['default'].version;
      this.get('cloudEftpos'); // force SDK to load
      this.get('updater'); // force update detection load
      if (!this.get('appAuth.is_authed')) {
        this.transitionTo('setup');
      }
    }
  });
});